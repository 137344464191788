 // Symbol(name=1inch Network, symbol=1INCH, meta=)
.currency-1INCH {
    background-color: #1b314f;
    color: white;
}
 // Symbol(name=Aave, symbol=AAVE, meta=)
.currency-AAVE {
    background-color: #ffffff;
    color: black;
}
 // Symbol(name=ABBC Coin, symbol=ABBC, meta=)
.currency-ABBC {
    background-color: #fe9b00;
    color: white;
}
 // Symbol(name=Arcblock, symbol=ABT, meta=)
.currency-ABT {
    background-color: #3effff;
    color: black;
}
 // Symbol(name=Alchemy Pay, symbol=ACH, meta=)
.currency-ACH {
    background-color: #020041;
    color: white;
}
 // Symbol(name=Achain, symbol=ACT, meta=)
.currency-ACT {
    background-color: #767dff;
    color: white;
}
 // Symbol(name=Cardano, symbol=ADA, meta=)
.currency-ADA {
    background-color: #2a71d0;
    color: white;
}
 // Symbol(name=Aidos Kuneen, symbol=ADK, meta=)
.currency-ADK {
    background-color: #151515;
    color: white;
}
 // Symbol(name=AdEx, symbol=ADX, meta=)
.currency-ADX {
    background-color: #1b75bc;
    color: white;
}
 // Symbol(name=u00c6ternity, symbol=AE, meta=)
.currency-AE {
    background-color: #de3f6b;
    color: white;
}
 // Symbol(name=SingularityNET, symbol=AGIX, meta=)
.currency-AGIX {
    background-color: #6916ff;
    color: white;
}
 // Symbol(name=Agoras: Currency of Tau, symbol=AGRS, meta=)
.currency-AGRS {
    background-color: #f49e00;
    color: white;
}
 // Symbol(name=Aion, symbol=AION, meta=)
.currency-AION {
    background-color: #1b1b1b;
    color: white;
}
 // Symbol(name=Akropolis, symbol=AKRO, meta=)
.currency-AKRO {
    background-color: #de65fd;
    color: white;
}
 // Symbol(name=Akash Network, symbol=AKT, meta=)
.currency-AKT {
    background-color: #ed3524;
    color: white;
}
 // Symbol(name=Algorand, symbol=ALGO, meta=)
.currency-ALGO {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Stella, symbol=ALPHA, meta=)
.currency-ALPHA {
    background-color: #183ca2;
    color: white;
}
 // Symbol(name=Amp, symbol=AMP, meta=)
.currency-AMP {
    background-color: #d9327c;
    color: white;
}
 // Symbol(name=Ampleforth, symbol=AMPL, meta=)
.currency-AMPL {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Ankr, symbol=ANKR, meta=)
.currency-ANKR {
    background-color: #235ae4;
    color: white;
}
 // Symbol(name=Aragon, symbol=ANT, meta=)
.currency-ANT {
    background-color: #3164fa;
    color: white;
}
 // Symbol(name=Anyswap, symbol=ANY, meta=)
.currency-ANY {
    background-color: #ffffff;
    color: black;
}
 // Symbol(name=Aurora, symbol=AOA, meta=)
.currency-AOA {
    background-color: #fff4fc;
    color: black;
}
 // Symbol(name=ApeCoin, symbol=APE, meta=)
.currency-APE {
    background-color: #ffffff;
    color: black;
}
 // Symbol(name=API3, symbol=API3, meta=)
.currency-API3 {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Apollo Currency, symbol=APL, meta=)
.currency-APL {
    background-color: #e5b349;
    color: black;
}
 // Symbol(name=AppCoins, symbol=APPC, meta=)
.currency-APPC {
    background-color: #fefefe;
    color: black;
}
 // Symbol(name=Aptos, symbol=APT, meta=)
.currency-APT {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Arweave, symbol=AR, meta=)
.currency-AR {
    background-color: #222326;
    color: white;
}
 // Symbol(name=Arbitrum, symbol=ARB, meta=)
.currency-ARB {
    background-color: #213147;
    color: white;
}
 // Symbol(name=Ardor, symbol=ARDR, meta=)
.currency-ARDR {
    background-color: #1562a1;
    color: white;
}
 // Symbol(name=Ark, symbol=ARK, meta=)
.currency-ARK {
    background-color: #f70000;
    color: white;
}
 // Symbol(name=Aeron, symbol=ARNX, meta=)
.currency-ARNX {
    background-color: #0092b5;
    color: white;
}
 // Symbol(name=ARPA, symbol=ARPA, meta=)
.currency-ARPA {
    background-color: #263145;
    color: white;
}
 // Symbol(name=Pirate Chain, symbol=ARRR, meta=)
.currency-ARRR {
    background-color: #c29f49;
    color: white;
}
 // Symbol(name=AllSafe, symbol=ASAFE, meta=)
.currency-ASAFE {
    background-color: #00688c;
    color: white;
}
 // Symbol(name=AirSwap, symbol=AST, meta=)
.currency-AST {
    background-color: #2a70fe;
    color: white;
}
 // Symbol(name=Astar, symbol=ASTR, meta=)
.currency-ASTR {
    background-color: #0ae2ff;
    color: black;
}
 // Symbol(name=Cosmos, symbol=ATOM, meta=)
.currency-ATOM {
    background-color: #2e3148;
    color: white;
}
 // Symbol(name=Avalanche, symbol=AVAX, meta=)
.currency-AVAX {
    background-color: #e84142;
    color: white;
}
 // Symbol(name=Axie Infinity, symbol=AXS, meta=)
.currency-AXS {
    background-color: #0055d5;
    color: white;
}
 // Symbol(name=BakeryToken, symbol=BAKE, meta=)
.currency-BAKE {
    background-color: #ac562a;
    color: white;
}
 // Symbol(name=Balancer, symbol=BAL, meta=)
.currency-BAL {
    background-color: #1e1e1e;
    color: white;
}
 // Symbol(name=Banano, symbol=BAN, meta=)
.currency-BAN {
    background-color: #fbdd11;
    color: black;
}
 // Symbol(name=Band Protocol, symbol=BAND, meta=)
.currency-BAND {
    background-color: #4520e6;
    color: white;
}
 // Symbol(name=Basic Attention Token, symbol=BAT, meta=)
.currency-BAT {
    background-color: #ff5000;
    color: white;
}
 // Symbol(name=Bitcoin Diamond, symbol=BCD, meta=)
.currency-BCD {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Bitcoin Cash, symbol=BCH, meta=)
.currency-BCH {
    background-color: #0ac18e;
    color: white;
}
 // Symbol(name=Bytecoin, symbol=BCN, meta=)
.currency-BCN {
    background-color: #f04086;
    color: white;
}
 // Symbol(name=Beam, symbol=BEAM, meta=)
.currency-BEAM {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Bela, symbol=BELA, meta=)
.currency-BELA {
    background-color: #13a0f6;
    color: white;
}
 // Symbol(name=BitForex Token, symbol=BF, meta=)
.currency-BF {
    background-color: #265fac;
    color: white;
}
 // Symbol(name=Bifrost, symbol=BFC, meta=)
.currency-BFC {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Bitget Token, symbol=BGB, meta=)
.currency-BGB {
    background-color: #00f0ff;
    color: black;
}
 // Symbol(name=Biconomy, symbol=BICO, meta=)
.currency-BICO {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Beefy, symbol=BIFI, meta=)
.currency-BIFI {
    background-color: #000000;
    color: white;
}
 // Symbol(name=BitDAO, symbol=BIT, meta=)
.currency-BIT {
    background-color: #c1ff3c;
    color: black;
}
 // Symbol(name=Bean Cash, symbol=BITB, meta=)
.currency-BITB {
    background-color: #ffffff;
    color: black;
}
 // Symbol(name=Bibox Token, symbol=BIX, meta=)
.currency-BIX {
    background-color: #000000;
    color: white;
}
 // Symbol(name=BlackCoin, symbol=BLK, meta=)
.currency-BLK {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Blocknet, symbol=BLOCK, meta=)
.currency-BLOCK {
    background-color: #101341;
    color: white;
}
 // Symbol(name=Bluzelle, symbol=BLZ, meta=)
.currency-BLZ {
    background-color: #fdba4d;
    color: black;
}
 // Symbol(name=Chimpion, symbol=BNANA, meta=)
.currency-BNANA {
    background-color: #3b3f4c;
    color: white;
}
 // Symbol(name=BNB, symbol=BNB, meta=)
.currency-BNB {
    background-color: #f0b90b;
    color: black;
}
 // Symbol(name=Bankera V2, symbol=BNK, meta=)
.currency-BNK {
    background-color: #17a4ba;
    color: white;
}
 // Symbol(name=Bancor, symbol=BNT, meta=)
.currency-BNT {
    background-color: #000d2b;
    color: white;
}
 // Symbol(name=Bounty0x, symbol=BNTY, meta=)
.currency-BNTY {
    background-color: #fd7a3d;
    color: white;
}
 // Symbol(name=BarnBridge, symbol=BOND, meta=)
.currency-BOND {
    background-color: #ff4339;
    color: white;
}
 // Symbol(name=Bone ShibaSwap, symbol=BONE, meta=)
.currency-BONE {
    background-color: #ca3900;
    color: white;
}
 // Symbol(name=Bonk, symbol=BONK, meta=)
.currency-BONK {
    background-color: #fdde00;
    color: black;
}
 // Symbol(name=Bread, symbol=BRD, meta=)
.currency-BRD {
    background-color: #fe5d86;
    color: white;
}
 // Symbol(name=Bitcoin SV, symbol=BSV, meta=)
.currency-BSV {
    background-color: #eab300;
    color: white;
}
 // Symbol(name=Biswap, symbol=BSW, meta=)
.currency-BSW {
    background-color: #ffffff;
    color: black;
}
 // Symbol(name=Bitcoin, symbol=BTC, meta=)
.currency-BTC {
    background-color: #f2a900;
    color: white;
}
 // Symbol(name=BitcoinZ, symbol=BTCZ, meta=)
.currency-BTCZ {
    background-color: #434343;
    color: white;
}
 // Symbol(name=Bitcoin Gold, symbol=BTG, meta=)
.currency-BTG {
    background-color: #eba809;
    color: white;
}
 // Symbol(name=Bytom, symbol=BTM, meta=)
.currency-BTM {
    background-color: #323333;
    color: white;
}
 // Symbol(name=Bottos, symbol=BTO, meta=)
.currency-BTO {
    background-color: #1c191a;
    color: white;
}
 // Symbol(name=Braintrust, symbol=BTRST, meta=)
.currency-BTRST {
    background-color: #1a1a1d;
    color: white;
}
 // Symbol(name=BitShares, symbol=BTS, meta=)
.currency-BTS {
    background-color: #34b9ea;
    color: white;
}
 // Symbol(name=BitTorrent, symbol=BTTOLD, meta=)
.currency-BTTOLD {
    background-color: #000000;
    color: white;
}
 // Symbol(name=BitCore, symbol=BTX, meta=)
.currency-BTX {
    background-color: #fb2ea3;
    color: white;
}
 // Symbol(name=BUSD, symbol=BUSD, meta=)
.currency-BUSD {
    background-color: #f0b90b;
    color: black;
}
 // Symbol(name=Baby Doge Coin, symbol=BabyDoge, meta=)
.currency-BabyDoge {
    background-color: #fda524;
    color: white;
}
 // Symbol(name=Coin98, symbol=C98, meta=)
.currency-C98 {
    background-color: #d9b432;
    color: black;
}
 // Symbol(name=PancakeSwap, symbol=CAKE, meta=)
.currency-CAKE {
    background-color: #d1884f;
    color: white;
}
 // Symbol(name=CATCOIN, symbol=CATS, meta=)
.currency-CATS {
    background-color: #79777f;
    color: white;
}
 // Symbol(name=Compound Dai, symbol=CDAI, meta=)
.currency-CDAI {
    background-color: #ffffff;
    color: black;
}
 // Symbol(name=Celsius, symbol=CEL, meta=)
.currency-CEL {
    background-color: #262761;
    color: white;
}
 // Symbol(name=Celo, symbol=CELO, meta=)
.currency-CELO {
    background-color: #fcff52;
    color: black;
}
 // Symbol(name=CENNZnet, symbol=CENNZ, meta=)
.currency-CENNZ {
    background-color: #0886fb;
    color: white;
}
 // Symbol(name=Centrifuge, symbol=CFG, meta=)
.currency-CFG {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Conflux, symbol=CFX, meta=)
.currency-CFX {
    background-color: #181818;
    color: white;
}
 // Symbol(name=ChatCoin, symbol=CHAT, meta=)
.currency-CHAT {
    background-color: #1c98f7;
    color: white;
}
 // Symbol(name=Nervos Network, symbol=CKB, meta=)
.currency-CKB {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Clams, symbol=CLAM, meta=)
.currency-CLAM {
    background-color: #4f99ca;
    color: white;
}
 // Symbol(name=CloakCoin, symbol=CLOAK, meta=)
.currency-CLOAK {
    background-color: #ff3a00;
    color: white;
}
 // Symbol(name=CoinLoan, symbol=CLT, meta=)
.currency-CLT {
    background-color: #3482fa;
    color: white;
}
 // Symbol(name=Cindicator, symbol=CND, meta=)
.currency-CND {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Cobinhood, symbol=COB, meta=)
.currency-COB {
    background-color: #13bf99;
    color: white;
}
 // Symbol(name=ColossusXT, symbol=COLX, meta=)
.currency-COLX {
    background-color: #77c3b0;
    color: white;
}
 // Symbol(name=Compound, symbol=COMP, meta=)
.currency-COMP {
    background-color: #070a0e;
    color: white;
}
 // Symbol(name=Core, symbol=CORE, meta=)
.currency-CORE {
    background-color: #ff9211;
    color: white;
}
 // Symbol(name=COTI, symbol=COTI, meta=)
.currency-COTI {
    background-color: #00467a;
    color: white;
}
 // Symbol(name=CoW Protocol, symbol=COW, meta=)
.currency-COW {
    background-color: #ffffff;
    color: black;
}
 // Symbol(name=Covalent, symbol=CQT, meta=)
.currency-CQT {
    background-color: #000625;
    color: white;
}
 // Symbol(name=Cream Finance, symbol=CREAM, meta=)
.currency-CREAM {
    background-color: #69e2dc;
    color: black;
}
 // Symbol(name=Cronos, symbol=CRO, meta=)
.currency-CRO {
    background-color: #002d74;
    color: white;
}
 // Symbol(name=Crypterium, symbol=CRPT, meta=)
.currency-CRPT {
    background-color: #2992fb;
    color: white;
}
 // Symbol(name=Curve DAO Token, symbol=CRV, meta=)
.currency-CRV {
    background-color: #7dff79;
    color: black;
}
 // Symbol(name=Crown, symbol=CRW, meta=)
.currency-CRW {
    background-color: #0f1529;
    color: white;
}
 // Symbol(name=Credits, symbol=CS, meta=)
.currency-CS {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Casper, symbol=CSPR, meta=)
.currency-CSPR {
    background-color: #ff473e;
    color: white;
}
 // Symbol(name=Cartesi, symbol=CTSI, meta=)
.currency-CTSI {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Cortex, symbol=CTXC, meta=)
.currency-CTXC {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Civic, symbol=CVC, meta=)
.currency-CVC {
    background-color: #3ab03e;
    color: white;
}
 // Symbol(name=CyberVein, symbol=CVT, meta=)
.currency-CVT {
    background-color: #1e0b49;
    color: white;
}
 // Symbol(name=Convex Finance, symbol=CVX, meta=)
.currency-CVX {
    background-color: #3a3a3a;
    color: white;
}
 // Symbol(name=Dai, symbol=DAI, meta=)
.currency-DAI {
    background-color: #1aab9b;
    color: white;
}
 // Symbol(name=Dash, symbol=DASH, meta=)
.currency-DASH {
    background-color: #008de4;
    color: white;
}
 // Symbol(name=Streamr, symbol=DATA, meta=)
.currency-DATA {
    background-color: #ff5c00;
    color: white;
}
 // Symbol(name=DeepBrain Chain, symbol=DBC, meta=)
.currency-DBC {
    background-color: #5bc1d4;
    color: white;
}
 // Symbol(name=Dentacoin, symbol=DCN, meta=)
.currency-DCN {
    background-color: #136485;
    color: white;
}
 // Symbol(name=Decred, symbol=DCR, meta=)
.currency-DCR {
    background-color: #77deb2;
    color: black;
}
 // Symbol(name=DerivaDAO, symbol=DDX, meta=)
.currency-DDX {
    background-color: #2d1680;
    color: white;
}
 // Symbol(name=Dent, symbol=DENT, meta=)
.currency-DENT {
    background-color: #666666;
    color: white;
}
 // Symbol(name=Decentralized Social, symbol=DESO, meta=)
.currency-DESO {
    background-color: #0057ff;
    color: white;
}
 // Symbol(name=DeFiChain, symbol=DFI, meta=)
.currency-DFI {
    background-color: #ff00af;
    color: white;
}
 // Symbol(name=DeGate, symbol=DG, meta=)
.currency-DG {
    background-color: #0080ff;
    color: white;
}
 // Symbol(name=DigiByte, symbol=DGB, meta=)
.currency-DGB {
    background-color: #002352;
    color: white;
}
 // Symbol(name=DigixDAO, symbol=DGD, meta=)
.currency-DGD {
    background-color: #f4d029;
    color: black;
}
 // Symbol(name=Divi, symbol=DIVI, meta=)
.currency-DIVI {
    background-color: #ffffff;
    color: black;
}
 // Symbol(name=Agrello, symbol=DLT, meta=)
.currency-DLT {
    background-color: #322a3f;
    color: white;
}
 // Symbol(name=Diamond, symbol=DMD, meta=)
.currency-DMD {
    background-color: #3e6492;
    color: white;
}
 // Symbol(name=DMarket, symbol=DMT, meta=)
.currency-DMT {
    background-color: #00dac7;
    color: white;
}
 // Symbol(name=district0x, symbol=DNT, meta=)
.currency-DNT {
    background-color: #2c398f;
    color: white;
}
 // Symbol(name=DogeBonk, symbol=DOBO, meta=)
.currency-DOBO {
    background-color: #fcca33;
    color: black;
}
 // Symbol(name=Dock, symbol=DOCK, meta=)
.currency-DOCK {
    background-color: #786dbc;
    color: white;
}
 // Symbol(name=Dogecoin, symbol=DOGE, meta=)
.currency-DOGE {
    background-color: #dfc66d;
    color: black;
}
 // Symbol(name=Polkadot, symbol=DOT, meta=)
.currency-DOT {
    background-color: #e6007a;
    color: white;
}
 // Symbol(name=Digital Reserve Currency, symbol=DRC, meta=)
.currency-DRC {
    background-color: #ffffff;
    color: black;
}
 // Symbol(name=Dragonchain, symbol=DRGN, meta=)
.currency-DRGN {
    background-color: #1797e5;
    color: white;
}
 // Symbol(name=DomRaider, symbol=DRT, meta=)
.currency-DRT {
    background-color: #ea5873;
    color: white;
}
 // Symbol(name=Davos.xyz USD, symbol=DUSD, meta=)
.currency-DUSD {
    background-color: #e94c70;
    color: white;
}
 // Symbol(name=Edgeless, symbol=EDG, meta=)
.currency-EDG {
    background-color: #2b1544;
    color: white;
}
 // Symbol(name=Open Campus, symbol=EDU, meta=)
.currency-EDU {
    background-color: #00edbe;
    color: black;
}
 // Symbol(name=MultiversX, symbol=EGLD, meta=)
.currency-EGLD {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Elastos, symbol=ELA, meta=)
.currency-ELA {
    background-color: #2a3d58;
    color: white;
}
 // Symbol(name=aelf, symbol=ELF, meta=)
.currency-ELF {
    background-color: #2b5eba;
    color: white;
}
 // Symbol(name=Dogelon Mars, symbol=ELON, meta=)
.currency-ELON {
    background-color: #fcad60;
    color: black;
}
 // Symbol(name=Emercoin, symbol=EMC, meta=)
.currency-EMC {
    background-color: #b49ffc;
    color: white;
}
 // Symbol(name=Ethena, symbol=ENA, meta=)
.currency-ENA {
    background-color: #ffffff;
    color: black;
}
 // Symbol(name=Enigma, symbol=ENG, meta=)
.currency-ENG {
    background-color: #2e2e2e;
    color: white;
}
 // Symbol(name=Enjin Coin, symbol=ENJ, meta=)
.currency-ENJ {
    background-color: #7866d5;
    color: white;
}
 // Symbol(name=Ethereum Name Service, symbol=ENS, meta=)
.currency-ENS {
    background-color: #52b5ff;
    color: white;
}
 // Symbol(name=EOS, symbol=EOS, meta=)
.currency-EOS {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Ergo, symbol=ERG, meta=)
.currency-ERG {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Ethereum Classic, symbol=ETC, meta=)
.currency-ETC {
    background-color: #0b8311;
    color: white;
}
 // Symbol(name=Ethereum, symbol=ETH, meta=)
.currency-ETH {
    background-color: #8a92b2;
    color: white;
}
 // Symbol(name=EthicHub, symbol=ETHIX, meta=)
.currency-ETHIX {
    background-color: #062f4f;
    color: white;
}
 // Symbol(name=EthereumPoW, symbol=ETHW, meta=)
.currency-ETHW {
    background-color: #e1f0f3;
    color: black;
}
 // Symbol(name=Electroneum, symbol=ETN, meta=)
.currency-ETN {
    background-color: #091b26;
    color: white;
}
 // Symbol(name=Metaverse ETP, symbol=ETP, meta=)
.currency-ETP {
    background-color: #3f3f3f;
    color: white;
}
 // Symbol(name=Euler, symbol=EUL, meta=)
.currency-EUL {
    background-color: #0c2129;
    color: white;
}
 // Symbol(name=STASIS EURO, symbol=EURS, meta=)
.currency-EURS {
    background-color: #1aaaf9;
    color: white;
}
 // Symbol(name=Everscale, symbol=EVER, meta=)
.currency-EVER {
    background-color: #6347f5;
    color: white;
}
 // Symbol(name=Everex, symbol=EVX, meta=)
.currency-EVX {
    background-color: #044aac;
    color: white;
}
 // Symbol(name=Energy Web Token, symbol=EWT, meta=)
.currency-EWT {
    background-color: #a566ff;
    color: white;
}
 // Symbol(name=Expanse, symbol=EXP, meta=)
.currency-EXP {
    background-color: #ffaa5c;
    color: black;
}
 // Symbol(name=First Digital USD, symbol=FDUSD, meta=)
.currency-FDUSD {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Fei USD, symbol=FEI, meta=)
.currency-FEI {
    background-color: #22996e;
    color: white;
}
 // Symbol(name=Fetch.ai, symbol=FET, meta=)
.currency-FET {
    background-color: #1f2843;
    color: white;
}
 // Symbol(name=Filecoin, symbol=FIL, meta=)
.currency-FIL {
    background-color: #0090ff;
    color: white;
}
 // Symbol(name=FLOKI, symbol=FLOKI, meta=)
.currency-FLOKI {
    background-color: #ffffff;
    color: black;
}
 // Symbol(name=Flow, symbol=FLOW, meta=)
.currency-FLOW {
    background-color: #00ef8b;
    color: black;
}
 // Symbol(name=Flare, symbol=FLR, meta=)
.currency-FLR {
    background-color: #e62058;
    color: white;
}
 // Symbol(name=Flux, symbol=FLUX, meta=)
.currency-FLUX {
    background-color: #2b61d1;
    color: white;
}
 // Symbol(name=Shapeshift FOX Token, symbol=FOX, meta=)
.currency-FOX {
    background-color: #131d27;
    color: white;
}
 // Symbol(name=Frax Price Index, symbol=FPI, meta=)
.currency-FPI {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Frax Price Index Share, symbol=FPIS, meta=)
.currency-FPIS {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Frax, symbol=FRAX, meta=)
.currency-FRAX {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Frax Finance - Frax Ether, symbol=FRXETH, meta=)
.currency-FRXETH {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Fusion, symbol=FSN, meta=)
.currency-FSN {
    background-color: #1e9ada;
    color: white;
}
 // Symbol(name=Feathercoin, symbol=FTC, meta=)
.currency-FTC {
    background-color: #384a53;
    color: white;
}
 // Symbol(name=Fantom, symbol=FTM, meta=)
.currency-FTM {
    background-color: #13b5ec;
    color: white;
}
 // Symbol(name=FTX Token, symbol=FTT, meta=)
.currency-FTT {
    background-color: #5fcade;
    color: black;
}
 // Symbol(name=Etherparty, symbol=FUEL, meta=)
.currency-FUEL {
    background-color: #4096d0;
    color: white;
}
 // Symbol(name=Function X, symbol=FX, meta=)
.currency-FX {
    background-color: #181818;
    color: white;
}
 // Symbol(name=Frax Share, symbol=FXS, meta=)
.currency-FXS {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Gala, symbol=GALA, meta=)
.currency-GALA {
    background-color: #060606;
    color: white;
}
 // Symbol(name=GameCredits, symbol=GAME, meta=)
.currency-GAME {
    background-color: #2d475b;
    color: white;
}
 // Symbol(name=Gamma, symbol=GAMMA, meta=)
.currency-GAMMA {
    background-color: #ff0000;
    color: white;
}
 // Symbol(name=Gas, symbol=GAS, meta=)
.currency-GAS {
    background-color: #00e497;
    color: white;
}
 // Symbol(name=Obyte, symbol=GBYTE, meta=)
.currency-GBYTE {
    background-color: #302c2c;
    color: white;
}
 // Symbol(name=Gifto, symbol=GFT, meta=)
.currency-GFT {
    background-color: #7f27ff;
    color: white;
}
 // Symbol(name=Golem, symbol=GLM, meta=)
.currency-GLM {
    background-color: #0d14ac;
    color: white;
}
 // Symbol(name=Moonbeam, symbol=GLMR, meta=)
.currency-GLMR {
    background-color: #5fc0c1;
    color: white;
}
 // Symbol(name=Golos Blockchain, symbol=GLS, meta=)
.currency-GLS {
    background-color: #367ec7;
    color: white;
}
 // Symbol(name=Gnosis, symbol=GNO, meta=)
.currency-GNO {
    background-color: #00193c;
    color: white;
}
 // Symbol(name=Grin, symbol=GRIN, meta=)
.currency-GRIN {
    background-color: #fef102;
    color: black;
}
 // Symbol(name=Groestlcoin, symbol=GRS, meta=)
.currency-GRS {
    background-color: #377e96;
    color: white;
}
 // Symbol(name=The Graph, symbol=GRT, meta=)
.currency-GRT {
    background-color: #6747ed;
    color: white;
}
 // Symbol(name=Global Social Chain, symbol=GSC, meta=)
.currency-GSC {
    background-color: #ff0060;
    color: white;
}
 // Symbol(name=Gitcoin, symbol=GTC, meta=)
.currency-GTC {
    background-color: #0e0333;
    color: white;
}
 // Symbol(name=Gemini Dollar, symbol=GUSD, meta=)
.currency-GUSD {
    background-color: #00dcfa;
    color: white;
}
 // Symbol(name=GXChain, symbol=GXC, meta=)
.currency-GXC {
    background-color: #b3b3b3;
    color: black;
}
 // Symbol(name=Hacken Token, symbol=HAI, meta=)
.currency-HAI {
    background-color: #3ef2d0;
    color: black;
}
 // Symbol(name=Hakka.Finance, symbol=HAKKA, meta=)
.currency-HAKKA {
    background-color: #06803f;
    color: white;
}
 // Symbol(name=Hedera, symbol=HBAR, meta=)
.currency-HBAR {
    background-color: #000000;
    color: white;
}
 // Symbol(name=HyperCash, symbol=HC, meta=)
.currency-HC {
    background-color: #56428e;
    color: white;
}
 // Symbol(name=HedgeTrade, symbol=HEDG, meta=)
.currency-HEDG {
    background-color: #53d27f;
    color: white;
}
 // Symbol(name=Metahero, symbol=HERO, meta=)
.currency-HERO {
    background-color: #00ffff;
    color: black;
}
 // Symbol(name=HEX, symbol=HEX, meta=)
.currency-HEX {
    background-color: #e06947;
    color: white;
}
 // Symbol(name=Hive, symbol=HIVE, meta=)
.currency-HIVE {
    background-color: #e31337;
    color: white;
}
 // Symbol(name=Handshake, symbol=HNS, meta=)
.currency-HNS {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Helium, symbol=HNT, meta=)
.currency-HNT {
    background-color: #38a2ff;
    color: white;
}
 // Symbol(name=Hord, symbol=HORD, meta=)
.currency-HORD {
    background-color: #27282d;
    color: white;
}
 // Symbol(name=Holo, symbol=HOT, meta=)
.currency-HOT {
    background-color: #007f88;
    color: white;
}
 // Symbol(name=Hyperblox, symbol=HPB, meta=)
.currency-HPB {
    background-color: #1591ca;
    color: white;
}
 // Symbol(name=Huobi Token, symbol=HT, meta=)
.currency-HT {
    background-color: #282e5b;
    color: white;
}
 // Symbol(name=HTMLCOIN, symbol=HTML, meta=)
.currency-HTML {
    background-color: #cfa967;
    color: white;
}
 // Symbol(name=HUSD, symbol=HUSD, meta=)
.currency-HUSD {
    background-color: #005ffa;
    color: white;
}
 // Symbol(name=Hush, symbol=HUSH, meta=)
.currency-HUSH {
    background-color: #292929;
    color: white;
}
 // Symbol(name=Internet Computer, symbol=ICP, meta=)
.currency-ICP {
    background-color: #29abe2;
    color: white;
}
 // Symbol(name=ICON, symbol=ICX, meta=)
.currency-ICX {
    background-color: #18a0b1;
    color: white;
}
 // Symbol(name=SPACE ID, symbol=ID, meta=)
.currency-ID {
    background-color: #1eefa4;
    color: black;
}
 // Symbol(name=IDEX, symbol=IDEX, meta=)
.currency-IDEX {
    background-color: #662bc9;
    color: white;
}
 // Symbol(name=Idle, symbol=IDLE, meta=)
.currency-IDLE {
    background-color: #0414d1;
    color: white;
}
 // Symbol(name=Ignis, symbol=IGNIS, meta=)
.currency-IGNIS {
    background-color: #da7029;
    color: white;
}
 // Symbol(name=Immutable, symbol=IMX, meta=)
.currency-IMX {
    background-color: #17b5cb;
    color: white;
}
 // Symbol(name=Injective, symbol=INJ, meta=)
.currency-INJ {
    background-color: #00a1fb;
    color: white;
}
 // Symbol(name=Ink, symbol=INK, meta=)
.currency-INK {
    background-color: #df1a14;
    color: white;
}
 // Symbol(name=ION, symbol=ION, meta=)
.currency-ION {
    background-color: #57beea;
    color: white;
}
 // Symbol(name=IOST, symbol=IOST, meta=)
.currency-IOST {
    background-color: #000000;
    color: white;
}
 // Symbol(name=IOTA, symbol=IOTA, meta=)
.currency-IOTA {
    background-color: #000000;
    color: white;
}
 // Symbol(name=IoTeX, symbol=IOTX, meta=)
.currency-IOTX {
    background-color: #00d4d5;
    color: white;
}
 // Symbol(name=IRISnet, symbol=IRIS, meta=)
.currency-IRIS {
    background-color: #694c9d;
    color: white;
}
 // Symbol(name=JasmyCoin, symbol=JASMY, meta=)
.currency-JASMY {
    background-color: #f7941c;
    color: white;
}
 // Symbol(name=JUST, symbol=JST, meta=)
.currency-JST {
    background-color: #b41514;
    color: white;
}
 // Symbol(name=Jupiter, symbol=JUP, meta=)
.currency-JUP {
    background-color: #141726;
    color: white;
}
 // Symbol(name=Kaspa, symbol=KAS, meta=)
.currency-KAS {
    background-color: #6fc7ba;
    color: black;
}
 // Symbol(name=Kambria, symbol=KAT, meta=)
.currency-KAT {
    background-color: #1aedc1;
    color: black;
}
 // Symbol(name=Kava, symbol=KAVA, meta=)
.currency-KAVA {
    background-color: #ff433e;
    color: white;
}
 // Symbol(name=Karatgold Coin, symbol=KBC, meta=)
.currency-KBC {
    background-color: #e2c76d;
    color: black;
}
 // Symbol(name=KuCoin Token, symbol=KCS, meta=)
.currency-KCS {
    background-color: #0093dd;
    color: white;
}
 // Symbol(name=Kadena, symbol=KDA, meta=)
.currency-KDA {
    background-color: #4a9079;
    color: white;
}
 // Symbol(name=Keep Network, symbol=KEEP, meta=)
.currency-KEEP {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Kin, symbol=KIN, meta=)
.currency-KIN {
    background-color: #003ec5;
    color: white;
}
 // Symbol(name=Klaytn, symbol=KLAY, meta=)
.currency-KLAY {
    background-color: #4f473b;
    color: white;
}
 // Symbol(name=Komodo, symbol=KMD, meta=)
.currency-KMD {
    background-color: #1b456e;
    color: white;
}
 // Symbol(name=Kyber Network Crystal v2, symbol=KNC, meta=)
.currency-KNC {
    background-color: #30ca9d;
    color: white;
}
 // Symbol(name=KanadeCoin, symbol=KNDC, meta=)
.currency-KNDC {
    background-color: #ee1d55;
    color: white;
}
 // Symbol(name=Kusama, symbol=KSM, meta=)
.currency-KSM {
    background-color: #000000;
    color: white;
}
 // Symbol(name=LATOKEN, symbol=LA, meta=)
.currency-LA {
    background-color: #161f37;
    color: white;
}
 // Symbol(name=LBRY Credits, symbol=LBC, meta=)
.currency-LBC {
    background-color: #2f4246;
    color: white;
}
 // Symbol(name=Litecoin Cash, symbol=LCC, meta=)
.currency-LCC {
    background-color: #ffffff;
    color: black;
}
 // Symbol(name=Lido DAO, symbol=LDO, meta=)
.currency-LDO {
    background-color: #f8a18e;
    color: black;
}
 // Symbol(name=UNUS SED LEO, symbol=LEO, meta=)
.currency-LEO {
    background-color: #f6e739;
    color: black;
}
 // Symbol(name=LikeCoin, symbol=LIKE, meta=)
.currency-LIKE {
    background-color: #28646e;
    color: white;
}
 // Symbol(name=Chainlink, symbol=LINK, meta=)
.currency-LINK {
    background-color: #375bd2;
    color: white;
}
 // Symbol(name=Loom Network, symbol=LOOM, meta=)
.currency-LOOM {
    background-color: #4bc0c8;
    color: white;
}
 // Symbol(name=Livepeer, symbol=LPT, meta=)
.currency-LPT {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Liquity, symbol=LQTY, meta=)
.currency-LQTY {
    background-color: #2eb6ea;
    color: white;
}
 // Symbol(name=Loopring, symbol=LRC, meta=)
.currency-LRC {
    background-color: #1b5ffe;
    color: white;
}
 // Symbol(name=Lisk, symbol=LSK, meta=)
.currency-LSK {
    background-color: #03173c;
    color: white;
}
 // Symbol(name=Litecoin, symbol=LTC, meta=)
.currency-LTC {
    background-color: #88cbf5;
    color: black;
}
 // Symbol(name=LTO Network, symbol=LTO, meta=)
.currency-LTO {
    background-color: #7bb6fe;
    color: white;
}
 // Symbol(name=Lunyr, symbol=LUN, meta=)
.currency-LUN {
    background-color: #f55749;
    color: white;
}
 // Symbol(name=Terra Classic, symbol=LUNC, meta=)
.currency-LUNC {
    background-color: #172852;
    color: white;
}
 // Symbol(name=Lympo, symbol=LYM, meta=)
.currency-LYM {
    background-color: #f26659;
    color: white;
}
 // Symbol(name=Treasure, symbol=MAGIC, meta=)
.currency-MAGIC {
    background-color: #dc2626;
    color: white;
}
 // Symbol(name=MaidSafeCoin, symbol=MAID, meta=)
.currency-MAID {
    background-color: #98bde6;
    color: black;
}
 // Symbol(name=Decentraland, symbol=MANA, meta=)
.currency-MANA {
    background-color: #ffffff;
    color: black;
}
 // Symbol(name=Mask Network, symbol=MASK, meta=)
.currency-MASK {
    background-color: #1c68f3;
    color: white;
}
 // Symbol(name=Polygon, symbol=MATIC, meta=)
.currency-MATIC {
    background-color: #7b3fe4;
    color: white;
}
 // Symbol(name=Moeda Loyalty Points, symbol=MDA, meta=)
.currency-MDA {
    background-color: #01a64f;
    color: white;
}
 // Symbol(name=MediShares, symbol=MDS, meta=)
.currency-MDS {
    background-color: #1e252c;
    color: white;
}
 // Symbol(name=Measurable Data Token, symbol=MDT, meta=)
.currency-MDT {
    background-color: #8acaff;
    color: black;
}
 // Symbol(name=MediBloc, symbol=MED, meta=)
.currency-MED {
    background-color: #00b0ff;
    color: white;
}
 // Symbol(name=MELD, symbol=MELD, meta=)
.currency-MELD {
    background-color: #e51b44;
    color: white;
}
 // Symbol(name=Merge, symbol=MERGE, meta=)
.currency-MERGE {
    background-color: #362265;
    color: white;
}
 // Symbol(name=Metadium, symbol=META, meta=)
.currency-META {
    background-color: #231f20;
    color: white;
}
 // Symbol(name=Metis, symbol=METIS, meta=)
.currency-METIS {
    background-color: #00dacc;
    color: white;
}
 // Symbol(name=Mina, symbol=MINA, meta=)
.currency-MINA {
    background-color: #2d2d2d;
    color: white;
}
 // Symbol(name=Mirror Protocol, symbol=MIR, meta=)
.currency-MIR {
    background-color: #232c45;
    color: white;
}
 // Symbol(name=Mithril, symbol=MITH, meta=)
.currency-MITH {
    background-color: #00316d;
    color: white;
}
 // Symbol(name=Maker, symbol=MKR, meta=)
.currency-MKR {
    background-color: #ffffff;
    color: black;
}
 // Symbol(name=MiL.k, symbol=MLK, meta=)
.currency-MLK {
    background-color: #22aea5;
    color: white;
}
 // Symbol(name=Enzyme, symbol=MLN, meta=)
.currency-MLN {
    background-color: #2a9dd5;
    color: white;
}
 // Symbol(name=MONNOS, symbol=MNS, meta=)
.currency-MNS {
    background-color: #0007bc;
    color: white;
}
 // Symbol(name=Mantle, symbol=MNT, meta=)
.currency-MNT {
    background-color: #000000;
    color: white;
}
 // Symbol(name=MonaCoin, symbol=MONA, meta=)
.currency-MONA {
    background-color: #dec799;
    color: black;
}
 // Symbol(name=88mph, symbol=MPH, meta=)
.currency-MPH {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Monetha, symbol=MTH, meta=)
.currency-MTH {
    background-color: #426fb6;
    color: white;
}
 // Symbol(name=Metal DAO, symbol=MTL, meta=)
.currency-MTL {
    background-color: #f54c6e;
    color: white;
}
 // Symbol(name=MVL, symbol=MVL, meta=)
.currency-MVL {
    background-color: #082e3d;
    color: white;
}
 // Symbol(name=MimbleWimbleCoin, symbol=MWC, meta=)
.currency-MWC {
    background-color: #9e00e7;
    color: white;
}
 // Symbol(name=Moonchain, symbol=MXC, meta=)
.currency-MXC {
    background-color: #ffffff;
    color: black;
}
 // Symbol(name=Mysterium, symbol=MYST, meta=)
.currency-MYST {
    background-color: #a6205e;
    color: white;
}
 // Symbol(name=Nebulas, symbol=NAS, meta=)
.currency-NAS {
    background-color: #222222;
    color: white;
}
 // Symbol(name=Navcoin, symbol=NAV, meta=)
.currency-NAV {
    background-color: #7d59b5;
    color: white;
}
 // Symbol(name=PolySwarm, symbol=NCT, meta=)
.currency-NCT {
    background-color: #8500ff;
    color: white;
}
 // Symbol(name=Nitro Network, symbol=NCash, meta=)
.currency-NCash {
    background-color: #36a9cf;
    color: white;
}
 // Symbol(name=NEAR Protocol, symbol=NEAR, meta=)
.currency-NEAR {
    background-color: #5f8afa;
    color: white;
}
 // Symbol(name=Neblio, symbol=NEBL, meta=)
.currency-NEBL {
    background-color: #50479e;
    color: white;
}
 // Symbol(name=Neo, symbol=NEO, meta=)
.currency-NEO {
    background-color: #00e599;
    color: white;
}
 // Symbol(name=NEST Protocol, symbol=NEST, meta=)
.currency-NEST {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Neumark, symbol=NEU, meta=)
.currency-NEU {
    background-color: #ffffff;
    color: black;
}
 // Symbol(name=Newton, symbol=NEW, meta=)
.currency-NEW {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Nexo, symbol=NEXO, meta=)
.currency-NEXO {
    background-color: #2853c3;
    color: white;
}
 // Symbol(name=APENFT, symbol=NFT, meta=)
.currency-NFT {
    background-color: #070001;
    color: white;
}
 // Symbol(name=NAGA, symbol=NGC, meta=)
.currency-NGC {
    background-color: #f80000;
    color: white;
}
 // Symbol(name=Nimiq, symbol=NIM, meta=)
.currency-NIM {
    background-color: #eba119;
    color: white;
}
 // Symbol(name=Autonio, symbol=NIOX, meta=)
.currency-NIOX {
    background-color: #70c9c9;
    color: black;
}
 // Symbol(name=NKN, symbol=NKN, meta=)
.currency-NKN {
    background-color: #122c47;
    color: white;
}
 // Symbol(name=NoLimitCoin, symbol=NLC, meta=)
.currency-NLC {
    background-color: #f28f01;
    color: white;
}
 // Symbol(name=Namecoin, symbol=NMC, meta=)
.currency-NMC {
    background-color: #186c9d;
    color: white;
}
 // Symbol(name=Numeraire, symbol=NMR, meta=)
.currency-NMR {
    background-color: #050708;
    color: white;
}
 // Symbol(name=Syntropy, symbol=NOIA, meta=)
.currency-NOIA {
    background-color: #050505;
    color: white;
}
 // Symbol(name=Pundi X (Old), symbol=NPXS, meta=)
.currency-NPXS {
    background-color: #181818;
    color: white;
}
 // Symbol(name=NuCypher, symbol=NU, meta=)
.currency-NU {
    background-color: #1e65f3;
    color: white;
}
 // Symbol(name=NULS, symbol=NULS, meta=)
.currency-NULS {
    background-color: #82bd39;
    color: white;
}
 // Symbol(name=Nxt, symbol=NXT, meta=)
.currency-NXT {
    background-color: #008fbb;
    color: white;
}
 // Symbol(name=OAX, symbol=OAX, meta=)
.currency-OAX {
    background-color: #164b79;
    color: white;
}
 // Symbol(name=Ocean Protocol, symbol=OCEAN, meta=)
.currency-OCEAN {
    background-color: #141414;
    color: white;
}
 // Symbol(name=Octopus Network, symbol=OCT, meta=)
.currency-OCT {
    background-color: #0f1369;
    color: white;
}
 // Symbol(name=Origin Ether, symbol=OETH, meta=)
.currency-OETH {
    background-color: #0074f0;
    color: white;
}
 // Symbol(name=Origin Protocol, symbol=OGN, meta=)
.currency-OGN {
    background-color: #0074f0;
    color: white;
}
 // Symbol(name=Origin DeFi Governance, symbol=OGV, meta=)
.currency-OGV {
    background-color: #030c17;
    color: white;
}
 // Symbol(name=Okcash, symbol=OK, meta=)
.currency-OK {
    background-color: #000000;
    color: white;
}
 // Symbol(name=OKB, symbol=OKB, meta=)
.currency-OKB {
    background-color: #3075ee;
    color: white;
}
 // Symbol(name=OMG Network, symbol=OMG, meta=)
.currency-OMG {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Omni, symbol=OMNI, meta=)
.currency-OMNI {
    background-color: #1c347a;
    color: white;
}
 // Symbol(name=Ondo, symbol=ONDO, meta=)
.currency-ONDO {
    background-color: #06070a;
    color: white;
}
 // Symbol(name=Harmony, symbol=ONE, meta=)
.currency-ONE {
    background-color: #28cbd8;
    color: white;
}
 // Symbol(name=Ontology, symbol=ONT, meta=)
.currency-ONT {
    background-color: #48a3ff;
    color: white;
}
 // Symbol(name=Optimism, symbol=OP, meta=)
.currency-OP {
    background-color: #ff0420;
    color: white;
}
 // Symbol(name=Orbs, symbol=ORBS, meta=)
.currency-ORBS {
    background-color: #556195;
    color: white;
}
 // Symbol(name=Osmosis, symbol=OSMO, meta=)
.currency-OSMO {
    background-color: #ffffff;
    color: black;
}
 // Symbol(name=OST, symbol=OST, meta=)
.currency-OST {
    background-color: #34445b;
    color: white;
}
 // Symbol(name=Origin Dollar, symbol=OUSD, meta=)
.currency-OUSD {
    background-color: #030c17;
    color: white;
}
 // Symbol(name=Orchid, symbol=OXT, meta=)
.currency-OXT {
    background-color: #5f45ba;
    color: white;
}
 // Symbol(name=Particl, symbol=PART, meta=)
.currency-PART {
    background-color: #45d492;
    color: white;
}
 // Symbol(name=Pascal, symbol=PASC, meta=)
.currency-PASC {
    background-color: #f7931e;
    color: white;
}
 // Symbol(name=PAX Gold, symbol=PAXG, meta=)
.currency-PAXG {
    background-color: #cca727;
    color: white;
}
 // Symbol(name=TenX, symbol=PAY, meta=)
.currency-PAY {
    background-color: #302c2c;
    color: white;
}
 // Symbol(name=Paypex, symbol=PAYX, meta=)
.currency-PAYX {
    background-color: #663300;
    color: white;
}
 // Symbol(name=ChainX, symbol=PCX, meta=)
.currency-PCX {
    background-color: #172026;
    color: white;
}
 // Symbol(name=PlayDapp, symbol=PDA, meta=)
.currency-PDA {
    background-color: #01d4c9;
    color: white;
}
 // Symbol(name=Pearl, symbol=PEARL, meta=)
.currency-PEARL {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Pendulum, symbol=PEN, meta=)
.currency-PEN {
    background-color: #32253e;
    color: white;
}
 // Symbol(name=Pendle, symbol=PENDLE, meta=)
.currency-PENDLE {
    background-color: #dedede;
    color: black;
}
 // Symbol(name=Pepe, symbol=PEPE, meta=)
.currency-PEPE {
    background-color: #3d8130;
    color: white;
}
 // Symbol(name=PERL.eco, symbol=PERL, meta=)
.currency-PERL {
    background-color: #b4e1ff;
    color: black;
}
 // Symbol(name=Perpetual Protocol, symbol=PERP, meta=)
.currency-PERP {
    background-color: #3ceaaa;
    color: black;
}
 // Symbol(name=PIVX, symbol=PIVX, meta=)
.currency-PIVX {
    background-color: #5e4778;
    color: white;
}
 // Symbol(name=Polybius, symbol=PLBT, meta=)
.currency-PLBT {
    background-color: #069dd8;
    color: white;
}
 // Symbol(name=Pillar, symbol=PLR, meta=)
.currency-PLR {
    background-color: #00ff24;
    color: black;
}
 // Symbol(name=Pluton, symbol=PLU, meta=)
.currency-PLU {
    background-color: #f1d485;
    color: black;
}
 // Symbol(name=PumaPay, symbol=PMA, meta=)
.currency-PMA {
    background-color: #1e69ff;
    color: white;
}
 // Symbol(name=Kleros, symbol=PNK, meta=)
.currency-PNK {
    background-color: #37393f;
    color: white;
}
 // Symbol(name=pNetwork, symbol=PNT, meta=)
.currency-PNT {
    background-color: #ff6666;
    color: white;
}
 // Symbol(name=Po.et, symbol=POE, meta=)
.currency-POE {
    background-color: #f2f2f2;
    color: black;
}
 // Symbol(name=Pocket Network, symbol=POKT, meta=)
.currency-POKT {
    background-color: #1d8aed;
    color: white;
}
 // Symbol(name=Polis, symbol=POLIS, meta=)
.currency-POLIS {
    background-color: #2c3e50;
    color: white;
}
 // Symbol(name=Polymath, symbol=POLY, meta=)
.currency-POLY {
    background-color: #4b5897;
    color: white;
}
 // Symbol(name=PooCoin, symbol=POOCOIN, meta=)
.currency-POOCOIN {
    background-color: #825c3c;
    color: white;
}
 // Symbol(name=PotCoin, symbol=POT, meta=)
.currency-POT {
    background-color: #105b2f;
    color: white;
}
 // Symbol(name=Powerledger, symbol=POWR, meta=)
.currency-POWR {
    background-color: #05bca9;
    color: white;
}
 // Symbol(name=Peercoin, symbol=PPC, meta=)
.currency-PPC {
    background-color: #3cb054;
    color: white;
}
 // Symbol(name=Populous, symbol=PPT, meta=)
.currency-PPT {
    background-color: #152743;
    color: white;
}
 // Symbol(name=Presearch, symbol=PRE, meta=)
.currency-PRE {
    background-color: #2d8eff;
    color: white;
}
 // Symbol(name=Propy, symbol=PRO, meta=)
.currency-PRO {
    background-color: #37a5f9;
    color: white;
}
 // Symbol(name=PARSIQ, symbol=PRQ, meta=)
.currency-PRQ {
    background-color: #005cc7;
    color: white;
}
 // Symbol(name=Patientory, symbol=PTOY, meta=)
.currency-PTOY {
    background-color: #42b34f;
    color: white;
}
 // Symbol(name=Pyth Network, symbol=PYTH, meta=)
.currency-PYTH {
    background-color: #e6dafe;
    color: black;
}
 // Symbol(name=QASH, symbol=QASH, meta=)
.currency-QASH {
    background-color: #1347e8;
    color: white;
}
 // Symbol(name=QuarkChain, symbol=QKC, meta=)
.currency-QKC {
    background-color: #071630;
    color: white;
}
 // Symbol(name=Quant, symbol=QNT, meta=)
.currency-QNT {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Quantum Resistant Ledger, symbol=QRL, meta=)
.currency-QRL {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Qtum, symbol=QTUM, meta=)
.currency-QTUM {
    background-color: #0645f7;
    color: white;
}
 // Symbol(name=Quarashi, symbol=QUA, meta=)
.currency-QUA {
    background-color: #89298c;
    color: white;
}
 // Symbol(name=RAMP, symbol=RAMP, meta=)
.currency-RAMP {
    background-color: #0d285a;
    color: white;
}
 // Symbol(name=Ripio Credit Network, symbol=RCN, meta=)
.currency-RCN {
    background-color: #3555f9;
    color: white;
}
 // Symbol(name=ReddCoin, symbol=RDD, meta=)
.currency-RDD {
    background-color: #ee3f26;
    color: white;
}
 // Symbol(name=Raiden Network Token, symbol=RDN, meta=)
.currency-RDN {
    background-color: #2a2a2a;
    color: white;
}
 // Symbol(name=Ren, symbol=REN, meta=)
.currency-REN {
    background-color: #001d3b;
    color: white;
}
 // Symbol(name=Augur, symbol=REP, meta=)
.currency-REP {
    background-color: #0e0e21;
    color: white;
}
 // Symbol(name=Request, symbol=REQ, meta=)
.currency-REQ {
    background-color: #00e59f;
    color: white;
}
 // Symbol(name=Rocket Pool ETH, symbol=RETH, meta=)
.currency-RETH {
    background-color: #ffffff;
    color: black;
}
 // Symbol(name=Revain, symbol=REV, meta=)
.currency-REV {
    background-color: #ffffff;
    color: black;
}
 // Symbol(name=Rootstock Infrastructure Framework, symbol=RIF, meta=)
.currency-RIF {
    background-color: #4b5cf0;
    color: white;
}
 // Symbol(name=iExec RLC, symbol=RLC, meta=)
.currency-RLC {
    background-color: #fcd15a;
    color: black;
}
 // Symbol(name=Ronin, symbol=RON, meta=)
.currency-RON {
    background-color: #004de5;
    color: white;
}
 // Symbol(name=Rook, symbol=ROOK, meta=)
.currency-ROOK {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Oasis Network, symbol=ROSE, meta=)
.currency-ROSE {
    background-color: #0089db;
    color: white;
}
 // Symbol(name=Reserve Rights, symbol=RSR, meta=)
.currency-RSR {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Reserve, symbol=RSV, meta=)
.currency-RSV {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Ruff, symbol=RUFF, meta=)
.currency-RUFF {
    background-color: #09cbcd;
    color: white;
}
 // Symbol(name=THORChain, symbol=RUNE, meta=)
.currency-RUNE {
    background-color: #2cbe8c;
    color: white;
}
 // Symbol(name=Ravencoin, symbol=RVN, meta=)
.currency-RVN {
    background-color: #384182;
    color: white;
}
 // Symbol(name=SALT, symbol=SALT, meta=)
.currency-SALT {
    background-color: #1beef4;
    color: black;
}
 // Symbol(name=Santiment Network Token, symbol=SAN, meta=)
.currency-SAN {
    background-color: #2b77b3;
    color: white;
}
 // Symbol(name=The Sandbox, symbol=SAND, meta=)
.currency-SAND {
    background-color: #ffffff;
    color: black;
}
 // Symbol(name=Steem Dollars, symbol=SBD, meta=)
.currency-SBD {
    background-color: #165098;
    color: white;
}
 // Symbol(name=Siacoin, symbol=SC, meta=)
.currency-SC {
    background-color: #20ee82;
    color: black;
}
 // Symbol(name=Secret, symbol=SCRT, meta=)
.currency-SCRT {
    background-color: #151a20;
    color: white;
}
 // Symbol(name=Seele-N, symbol=SEELE, meta=)
.currency-SEELE {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Sei, symbol=SEI, meta=)
.currency-SEI {
    background-color: #0b2632;
    color: white;
}
 // Symbol(name=SENSO, symbol=SENSO, meta=)
.currency-SENSO {
    background-color: #000000;
    color: white;
}
 // Symbol(name=saffron.finance, symbol=SFI, meta=)
.currency-SFI {
    background-color: #000000;
    color: white;
}
 // Symbol(name=SafePal, symbol=SFP, meta=)
.currency-SFP {
    background-color: #050101;
    color: white;
}
 // Symbol(name=Frax Staked Ether, symbol=SFRXETH, meta=)
.currency-SFRXETH {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Safe Haven, symbol=SHA, meta=)
.currency-SHA {
    background-color: #837a87;
    color: white;
}
 // Symbol(name=Shiba Inu, symbol=SHIB, meta=)
.currency-SHIB {
    background-color: #f06500;
    color: white;
}
 // Symbol(name=ShibaDoge, symbol=SHIBDOGE, meta=)
.currency-SHIBDOGE {
    background-color: #ecdb51;
    color: black;
}
 // Symbol(name=SIBCoin, symbol=SIB, meta=)
.currency-SIB {
    background-color: #ffffff;
    color: black;
}
 // Symbol(name=Skycoin, symbol=SKY, meta=)
.currency-SKY {
    background-color: #0072ff;
    color: white;
}
 // Symbol(name=Smooth Love Potion, symbol=SLP, meta=)
.currency-SLP {
    background-color: #77baa1;
    color: white;
}
 // Symbol(name=SaluS, symbol=SLS, meta=)
.currency-SLS {
    background-color: #8e9495;
    color: white;
}
 // Symbol(name=SmartCash, symbol=SMART, meta=)
.currency-SMART {
    background-color: #fec60d;
    color: black;
}
 // Symbol(name=SONM (BEP-20), symbol=SNM, meta=)
.currency-SNM {
    background-color: #0b1c26;
    color: white;
}
 // Symbol(name=SnowSwap, symbol=SNOW, meta=)
.currency-SNOW {
    background-color: #ffffff;
    color: black;
}
 // Symbol(name=Status, symbol=SNT, meta=)
.currency-SNT {
    background-color: #5b6dee;
    color: white;
}
 // Symbol(name=Synthetix, symbol=SNX, meta=)
.currency-SNX {
    background-color: #170659;
    color: white;
}
 // Symbol(name=Solana, symbol=SOL, meta=)
.currency-SOL {
    background-color: #14f195;
    color: black;
}
 // Symbol(name=Phantasma, symbol=SOUL, meta=)
.currency-SOUL {
    background-color: #6ca2cb;
    color: white;
}
 // Symbol(name=Serum, symbol=SRM, meta=)
.currency-SRM {
    background-color: #00afcc;
    color: white;
}
 // Symbol(name=SIRIN LABS Token, symbol=SRN, meta=)
.currency-SRN {
    background-color: #1c1c1c;
    color: white;
}
 // Symbol(name=STAKE, symbol=STAKE, meta=)
.currency-STAKE {
    background-color: #48a9a6;
    color: white;
}
 // Symbol(name=Startcoin, symbol=START, meta=)
.currency-START {
    background-color: #01aef0;
    color: white;
}
 // Symbol(name=Student Coin, symbol=STC, meta=)
.currency-STC {
    background-color: #17105d;
    color: white;
}
 // Symbol(name=Steem, symbol=STEEM, meta=)
.currency-STEEM {
    background-color: #171fc9;
    color: white;
}
 // Symbol(name=Storj, symbol=STORJ, meta=)
.currency-STORJ {
    background-color: #2683ff;
    color: white;
}
 // Symbol(name=Stratis [Old], symbol=STRAX, meta=)
.currency-STRAX {
    background-color: #20b9c0;
    color: white;
}
 // Symbol(name=Starknet, symbol=STRK, meta=)
.currency-STRK {
    background-color: #29296e;
    color: white;
}
 // Symbol(name=Strong, symbol=STRONG, meta=)
.currency-STRONG {
    background-color: #0e67ae;
    color: white;
}
 // Symbol(name=Stacks, symbol=STX, meta=)
.currency-STX {
    background-color: #5546ff;
    color: white;
}
 // Symbol(name=Substratum, symbol=SUB, meta=)
.currency-SUB {
    background-color: #e53431;
    color: white;
}
 // Symbol(name=Sui, symbol=SUI, meta=)
.currency-SUI {
    background-color: #6fbcf0;
    color: white;
}
 // Symbol(name=Sumokoin, symbol=SUMO, meta=)
.currency-SUMO {
    background-color: #2d9cdb;
    color: white;
}
 // Symbol(name=SushiSwap, symbol=SUSHI, meta=)
.currency-SUSHI {
    background-color: #ffffff;
    color: black;
}
 // Symbol(name=suterusu, symbol=SUTER, meta=)
.currency-SUTER {
    background-color: #242328;
    color: white;
}
 // Symbol(name=Swarm City, symbol=SWT, meta=)
.currency-SWT {
    background-color: #efd96f;
    color: black;
}
 // Symbol(name=Carbon Protocol, symbol=SWTH, meta=)
.currency-SWTH {
    background-color: #063c4f;
    color: white;
}
 // Symbol(name=Syscoin, symbol=SYS, meta=)
.currency-SYS {
    background-color: #008dd0;
    color: white;
}
 // Symbol(name=Threshold, symbol=T, meta=)
.currency-T {
    background-color: #7c47ee;
    color: white;
}
 // Symbol(name=Bittensor, symbol=TAO, meta=)
.currency-TAO {
    background-color: #292929;
    color: white;
}
 // Symbol(name=Lamden, symbol=TAU, meta=)
.currency-TAU {
    background-color: #c42c79;
    color: white;
}
 // Symbol(name=Tokenbox, symbol=TBX, meta=)
.currency-TBX {
    background-color: #5244d4;
    color: white;
}
 // Symbol(name=Telcoin, symbol=TEL, meta=)
.currency-TEL {
    background-color: #14c8ff;
    color: white;
}
 // Symbol(name=TemDAO, symbol=TEM, meta=)
.currency-TEM {
    background-color: #a40b5d;
    color: white;
}
 // Symbol(name=Tokenomy, symbol=TEN, meta=)
.currency-TEN {
    background-color: #0899cd;
    color: white;
}
 // Symbol(name=TERA, symbol=TERA, meta=)
.currency-TERA {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Theta Fuel, symbol=TFUEL, meta=)
.currency-TFUEL {
    background-color: #ffffff;
    color: black;
}
 // Symbol(name=Theca, symbol=THECA, meta=)
.currency-THECA {
    background-color: #ffffff;
    color: black;
}
 // Symbol(name=Theta Network, symbol=THETA, meta=)
.currency-THETA {
    background-color: #1b1f2b;
    color: white;
}
 // Symbol(name=Celestia, symbol=TIA, meta=)
.currency-TIA {
    background-color: #7b2bf9;
    color: white;
}
 // Symbol(name=Monolith, symbol=TKN, meta=)
.currency-TKN {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Tokes, symbol=TKS, meta=)
.currency-TKS {
    background-color: #895af8;
    color: white;
}
 // Symbol(name=Trinity Network Credit, symbol=TNC, meta=)
.currency-TNC {
    background-color: #ff439b;
    color: white;
}
 // Symbol(name=Toncoin, symbol=TON, meta=)
.currency-TON {
    background-color: #0088cc;
    color: white;
}
 // Symbol(name=Torex, symbol=TOR, meta=)
.currency-TOR {
    background-color: #2cc981;
    color: white;
}
 // Symbol(name=Tornado Cash, symbol=TORN, meta=)
.currency-TORN {
    background-color: #94febf;
    color: black;
}
 // Symbol(name=OriginTrail, symbol=TRAC, meta=)
.currency-TRAC {
    background-color: #1d2667;
    color: white;
}
 // Symbol(name=Tellor, symbol=TRB, meta=)
.currency-TRB {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Tribe, symbol=TRIBE, meta=)
.currency-TRIBE {
    background-color: #178dd0;
    color: white;
}
 // Symbol(name=TurtleCoin, symbol=TRTL, meta=)
.currency-TRTL {
    background-color: #00843d;
    color: white;
}
 // Symbol(name=TRON, symbol=TRX, meta=)
.currency-TRX {
    background-color: #dc062b;
    color: white;
}
 // Symbol(name=TrueUSD, symbol=TUSD, meta=)
.currency-TUSD {
    background-color: #002868;
    color: white;
}
 // Symbol(name=TrezarCoin, symbol=TZC, meta=)
.currency-TZC {
    background-color: #374851;
    color: white;
}
 // Symbol(name=UMA, symbol=UMA, meta=)
.currency-UMA {
    background-color: #ff4a4a;
    color: white;
}
 // Symbol(name=Uniswap, symbol=UNI, meta=)
.currency-UNI {
    background-color: #ff007a;
    color: white;
}
 // Symbol(name=Ultra, symbol=UOS, meta=)
.currency-UOS {
    background-color: #7a52d1;
    color: white;
}
 // Symbol(name=Uquid Coin, symbol=UQC, meta=)
.currency-UQC {
    background-color: #e85c50;
    color: white;
}
 // Symbol(name=USDC, symbol=USDC, meta=)
.currency-USDC {
    background-color: #2775ca;
    color: white;
}
 // Symbol(name=USDD, symbol=USDD, meta=)
.currency-USDD {
    background-color: #216c58;
    color: white;
}
 // Symbol(name=Pax Dollar, symbol=USDP, meta=)
.currency-USDP {
    background-color: #085229;
    color: white;
}
 // Symbol(name=Tether USDt, symbol=USDT, meta=)
.currency-USDT {
    background-color: #85bb65;
    color: white;
}
 // Symbol(name=xMoney, symbol=UTK, meta=)
.currency-UTK {
    background-color: #30367a;
    color: white;
}
 // Symbol(name=Veil, symbol=VEIL, meta=)
.currency-VEIL {
    background-color: #017bdf;
    color: white;
}
 // Symbol(name=Veritaseum, symbol=VERI, meta=)
.currency-VERI {
    background-color: #ff9933;
    color: white;
}
 // Symbol(name=VeChain, symbol=VET, meta=)
.currency-VET {
    background-color: #82be00;
    color: white;
}
 // Symbol(name=Viacoin, symbol=VIA, meta=)
.currency-VIA {
    background-color: #565656;
    color: white;
}
 // Symbol(name=VIBE, symbol=VIBE, meta=)
.currency-VIBE {
    background-color: #338be5;
    color: white;
}
 // Symbol(name=VITE, symbol=VITE, meta=)
.currency-VITE {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Velas, symbol=VLX, meta=)
.currency-VLX {
    background-color: #0037c1;
    color: white;
}
 // Symbol(name=Vesper, symbol=VSP, meta=)
.currency-VSP {
    background-color: #4138ac;
    color: white;
}
 // Symbol(name=v.systems, symbol=VSYS, meta=)
.currency-VSYS {
    background-color: #ff8836;
    color: white;
}
 // Symbol(name=Vertcoin, symbol=VTC, meta=)
.currency-VTC {
    background-color: #048657;
    color: white;
}
 // Symbol(name=VeThor Token, symbol=VTHO, meta=)
.currency-VTHO {
    background-color: #2a5284;
    color: white;
}
 // Symbol(name=Vectorspace AI, symbol=VXV, meta=)
.currency-VXV {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Wormhole, symbol=W, meta=)
.currency-W {
    background-color: #222263;
    color: white;
}
 // Symbol(name=Wanchain, symbol=WAN, meta=)
.currency-WAN {
    background-color: #136aad;
    color: white;
}
 // Symbol(name=Waves, symbol=WAVES, meta=)
.currency-WAVES {
    background-color: #0055fe;
    color: white;
}
 // Symbol(name=WAX, symbol=WAXP, meta=)
.currency-WAXP {
    background-color: #f89022;
    color: white;
}
 // Symbol(name=Wrapped Bitcoin, symbol=WBTC, meta=)
.currency-WBTC {
    background-color: #f09242;
    color: white;
}
 // Symbol(name=Wagerr, symbol=WGR, meta=)
.currency-WGR {
    background-color: #b80000;
    color: white;
}
 // Symbol(name=WaykiChain, symbol=WICC, meta=)
.currency-WICC {
    background-color: #002aac;
    color: white;
}
 // Symbol(name=dogwifhat, symbol=WIF, meta=)
.currency-WIF {
    background-color: #efbfa8;
    color: black;
}
 // Symbol(name=Wing Finance, symbol=WING, meta=)
.currency-WING {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Wings, symbol=WINGS, meta=)
.currency-WINGS {
    background-color: #0dc9f7;
    color: white;
}
 // Symbol(name=Worldcoin, symbol=WLD, meta=)
.currency-WLD {
    background-color: #000000;
    color: white;
}
 // Symbol(name=WOO, symbol=WOO, meta=)
.currency-WOO {
    background-color: #20252f;
    color: white;
}
 // Symbol(name=Wownero, symbol=WOW, meta=)
.currency-WOW {
    background-color: #ff2ad4;
    color: white;
}
 // Symbol(name=Waltonchain, symbol=WTC, meta=)
.currency-WTC {
    background-color: #8400fd;
    color: white;
}
 // Symbol(name=Asch, symbol=XAS, meta=)
.currency-XAS {
    background-color: #faa00d;
    color: white;
}
 // Symbol(name=Tether Gold, symbol=XAUt, meta=)
.currency-XAUt {
    background-color: #ffffff;
    color: black;
}
 // Symbol(name=Bitcoin Plus, symbol=XBC, meta=)
.currency-XBC {
    background-color: #f7931a;
    color: white;
}
 // Symbol(name=XTRABYTES, symbol=XBY, meta=)
.currency-XBY {
    background-color: #56f4f1;
    color: black;
}
 // Symbol(name=Chia, symbol=XCH, meta=)
.currency-XCH {
    background-color: #3aac59;
    color: white;
}
 // Symbol(name=Coinmetro Token, symbol=XCM, meta=)
.currency-XCM {
    background-color: #131218;
    color: white;
}
 // Symbol(name=Counterparty, symbol=XCP, meta=)
.currency-XCP {
    background-color: #ec1550;
    color: white;
}
 // Symbol(name=DigitalNote, symbol=XDN, meta=)
.currency-XDN {
    background-color: #4f7aa2;
    color: white;
}
 // Symbol(name=eCash, symbol=XEC, meta=)
.currency-XEC {
    background-color: #ffffff;
    color: black;
}
 // Symbol(name=NEM, symbol=XEM, meta=)
.currency-XEM {
    background-color: #f7a800;
    color: white;
}
 // Symbol(name=Haven Protocol, symbol=XHV, meta=)
.currency-XHV {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Mixin, symbol=XIN, meta=)
.currency-XIN {
    background-color: #1eb5fa;
    color: white;
}
 // Symbol(name=Stellar, symbol=XLM, meta=)
.currency-XLM {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Monero, symbol=XMR, meta=)
.currency-XMR {
    background-color: #f26822;
    color: white;
}
 // Symbol(name=XMax, symbol=XMX, meta=)
.currency-XMX {
    background-color: #ff0033;
    color: white;
}
 // Symbol(name=Nano, symbol=XNO, meta=)
.currency-XNO {
    background-color: #209ce9;
    color: white;
}
 // Symbol(name=SORA, symbol=XOR, meta=)
.currency-XOR {
    background-color: #e3242d;
    color: white;
}
 // Symbol(name=XPA, symbol=XPA, meta=)
.currency-XPA {
    background-color: #4fa784;
    color: white;
}
 // Symbol(name=Primecoin, symbol=XPM, meta=)
.currency-XPM {
    background-color: #ffd81b;
    color: black;
}
 // Symbol(name=XPR Network, symbol=XPR, meta=)
.currency-XPR {
    background-color: #6b57a5;
    color: white;
}
 // Symbol(name=XRP, symbol=XRP, meta=)
.currency-XRP {
    background-color: #008cff;
    color: white;
}
 // Symbol(name=Tezos, symbol=XTZ, meta=)
.currency-XTZ {
    background-color: #2c7df7;
    color: white;
}
 // Symbol(name=Verge, symbol=XVG, meta=)
.currency-XVG {
    background-color: #37bde2;
    color: white;
}
 // Symbol(name=WhiteCoin, symbol=XWC, meta=)
.currency-XWC {
    background-color: #40506b;
    color: white;
}
 // Symbol(name=Symbol, symbol=XYM, meta=)
.currency-XYM {
    background-color: #7000df;
    color: white;
}
 // Symbol(name=XYO, symbol=XYO, meta=)
.currency-XYO {
    background-color: #8c91c7;
    color: white;
}
 // Symbol(name=yearn.finance, symbol=YFI, meta=)
.currency-YFI {
    background-color: #ffffff;
    color: black;
}
 // Symbol(name=YooShi, symbol=YOOSHI, meta=)
.currency-YOOSHI {
    background-color: #1d972f;
    color: white;
}
 // Symbol(name=ZClassic, symbol=ZCL, meta=)
.currency-ZCL {
    background-color: #c87035;
    color: white;
}
 // Symbol(name=Zcash, symbol=ZEC, meta=)
.currency-ZEC {
    background-color: #000000;
    color: white;
}
 // Symbol(name=Zilliqa, symbol=ZIL, meta=)
.currency-ZIL {
    background-color: #48c0be;
    color: white;
}
 // Symbol(name=Zelwin, symbol=ZLW, meta=)
.currency-ZLW {
    background-color: #ffffff;
    color: black;
}
 // Symbol(name=0x Protocol, symbol=ZRX, meta=)
.currency-ZRX {
    background-color: #231815;
    color: white;
}
 // Symbol(name=pTokens BTC, symbol=pBTC, meta=)
.currency-pBTC {
    background-color: #ff6666;
    color: white;
}
 // Symbol(name=Lido Staked ETH, symbol=stETH, meta=)
.currency-stETH {
    background-color: #00a3ff;
    color: white;
}
