// Here you can add other styles
body {
  background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
}

td {
  vertical-align: middle;
}

span.badge {
  width: 45px;
}

.progress, span.badge {
  color: black;
  border: solid 1px black;
  border-radius: 2px;
}

.fit-content {
  width: fit-content;
  min-width: 1200px;
}

.vh-80 {
  height: calc(80vh);
}

.input-group-auto {
  display: inline-block;
}

.input-group-vertical {
  text-align: center;
}

.input-group-vertical *:first-child,
.input-group-vertical *:not(:first-child),
html:not([dir=rtl]) .input-group.input-group-vertical > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0;
  margin-top: -1px;
  min-width: 50px;
}

.form-control-auto {
  width: auto;
  display: inline-block;
}

// FUND FORM

.fund-select {
  border: solid 1px #3c3c3d;
  box-sizing: border-box;
}

// DATASET FORM

.dataset-label span, .dataset-form select, .currency-and-amount-form select, select.interval {
  background-image: none;
  border-radius: 0;
  text-align: center;
  font-weight: bold;
  padding: 1px 3px;
  font-size: 10px;
  display: inline-block;
}

.dataset-label.r-90 * {
  width: auto;
  min-width: auto;
  padding: 5px 1px;
}

.dataset-form select, .dataset-label span {
  border-color: #3c3c3d;
}

.dataset-form.exchange {
}

.exchange-KR {
  background-color: #5848d5;
  color: white;
}

.exchange-TS {
  background-color: #ff0000;
  color: white;
}

.exchange-CB {
  background-color: #0052FF;
  color: white;
}

.exchange-BN, .exchange-BV {
  background-color: #0C0E12;
  color: #F2BC07;
}

.exchange-TV {
  background-color: #0C0E12;
  color: #FFFFFF;
}

.dataset-label > .name {
  background: #1AAB9B;
  color: #FFFFFF;
}

.currency {
  width: 40px;
  white-space: nowrap;
}

.currency-GBP {
  background-color: mediumpurple;
  color: #ffffff;
}

.commission-input input.commissionFixed {
  border-right: none;
}

.commission-input input.commissionFixed {
  border-left: none;
}

/////// IDs

.id-link {
  text-decoration: none;
  font-weight: bold;
  color: var(--cui-heading-color);
  cursor: pointer;
  display: flex;
  align-items: center;
}

/////// DASHBOARD

body .dashboard-component, body .top-level-component {
  border: solid 2px #dddddd;
  background: linear-gradient(55deg, #ffffff 0%, #fcfcfc 100%)
}

.dashboard-component {
  width: 100%;
  height: 100%;
}

body.dark-mode .dashboard-component, body.dark-mode .top-level-component {
  border: solid 2px #444444;
  background: linear-gradient(55deg, #000000 0%, #111114 40%, #111114 60%, #000000 100%)
}

.dashboard-component, .top-level-component {
  border: solid 1.5px #dddddd;
  border-radius: 3px;
  overflow: hidden;
}

body .dashboard-component.sub-dashboard {
  background: transparent;
  border-width: 0;
}

.dash-item {
  padding: 3px;
}

////// REACT GRID LAYOUT

.react-resizable-handle {
  z-index: 10;
}

//////// AQL EDITOR

div.cm-s-juejin.CodeMirror {
  background-color: #ffffff;
  font-size: 10px;
}

//@formatter:off
// If only they'd envisaged someone wanting to change the height of their component...
.aql-editor.h-auto > .CodeMirror { height: auto !important; }
.aql-editor.h-100 > .CodeMirror { height: 100px !important; }
.aql-editor.h-200  > .CodeMirror { height: 200px !important; }
.aql-editor.h-300  > .CodeMirror { height: 300px !important; }
.aql-editor.h-400  > .CodeMirror { height: 400px !important; }
.aql-editor.h-500  > .CodeMirror { height: 500px !important; }
.aql-editor.h-600  > .CodeMirror { height: 600px !important; }
.aql-editor.h-700  > .CodeMirror { height: 700px !important; }
.aql-editor.h-800  > .CodeMirror { height: 800px !important; }
.aql-editor.h-900  > .CodeMirror { height: 900px !important; }
.aql-editor.h-1000  > .CodeMirror { height: 1000px !important; }
.aql-editor.h-1100  > .CodeMirror { height: 1100px !important; }
.aql-editor.h-1200  > .CodeMirror { height: 1200px !important; }
.aql-editor.h-1300  > .CodeMirror { height: 1300px !important; }
.aql-editor.h-1400  > .CodeMirror { height: 1400px !important; }
.aql-editor.h-1500  > .CodeMirror { height: 1500px !important; }
.aql-editor.h-1600  > .CodeMirror { height: 1600px !important; }
//@formatter:on

.code-mirror-wrapper-div {
  border: solid 1px $border-color;
}

///// CONFGIURABLE CHART

.configuragble-chart {
  //background-color: #7c649a;
}

.sidebar, .card-header {
  font-size: $font-size-lg
}

///// DATA DASHBOARD

.button-right {
  margin: 0.8rem !important;
  float: right;
  display: inline-block;
}

/////// STANDARD COMPONENTS

.btn.btn-primary {
  color: #faeed8;
}

.dark-theme .btn.btn-primary {
  color: #000000;
}

.pointer-cursor {
  cursor: pointer;
}

.strategy-label {
  display: inline-block;
  background-color: #ffa947;
  width: 60px;
}

.r-90 {
  writing-mode: vertical-rl;
}

.display-linebreaks {
  white-space: pre-line;
}

/// DROPZONE

.drop-zone {
  border: dashed 3px gray;
  padding: 150px;
  background-color: #e3e3e3;
}

// DOMAIN COLORS

.bg-profit {
  background-color: #28a428
}

.bg-loss {
  background-color: #a92323
}

.bg-profit-light {
  background-color: #bfdcbf
}

.bg-loss-light {
  background-color: #d9aeae
}

// ACCORDION

.accordion-button {
  background-color: #5797e6;
  font-weight: bold;
  color: white;
  padding: 3px 8px;
}

.accordion-body {
  padding: 0;
}

.accordion-button::after {
  width: 10px;
  height: 10px;
  background-size: auto;
}

// ADMIN

.animate-bg {
  transition: background-color 500ms linear;
}

// TABLES

.table {
  --cui-table-bg: transparent;
}

///// PROGRESS BAR

$openColor: #466dec;
$closeColor: #e06060;

.progress .bg-close {
  background-color: $closeColor;
}

.progress .bg-open {
  background-color: $openColor;
}

.w-20 {
    width: 20%;
}
